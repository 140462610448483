<template>
  <div v-if="audits.length > 0">
      <hr/>
      <h3>Audit</h3>
        <b-table
          striped
          hover
          sort-icon-left          
          :items="audits"
          :fields="auditFields"
        >
          <template #cell(created_at)="data">
            {{showDate(data.item.created_at)}}
          </template>     
          <template #cell(changes)="data">
            <span v-html="showChanges(data.item)"></span>
          </template>                  
        </b-table>
  </div>
</template>



<script>

  import ApiService from "@/core/services/api.service";
  import moment from "moment";
  import { detailedDiff } from 'deep-object-diff';

  export default {
    data() {
      return {
        audits: [],
        auditFields: [
            { key: "created_at", label: "When" , sortable: true},
            { key: "user", label: "By Who", sortable: true },
            { key: "audit", label: "Action", sortable: true  },
            { key: "changes", label: "Changes", sortable: false  },
        ],        
      }
    },
    props: {
        id: String,
        action: String,
    },
    mounted() {
        this.getAudits();
    },
    methods: {

        showDate(date) {
            return moment(date).format('L HH:mm')
        },

        showChanges(record) {
          var retStr = "";

          if (record.olddata == '') { record.olddata = '{}'}

          var result = detailedDiff(JSON.parse(record.olddata), JSON.parse(record.data));
          if (this.action == "Risk") {
            retStr = this.riskOutput(result.updated);
          }
          if (this.action == "System") {
            retStr = this.systemOutput(result.updated);
          }
          if (this.action == "Task") {

            console.log(this.action,result.updated)            
            retStr = this.taskOutput(result.updated);
          }

          
          
          return retStr;
        },

        riskOutput(data) {
          var output = "";

          if ((typeof data) == "string") {
            data =JSON.parse(data);
          }

          if (data.hasOwnProperty('name')) { output = output + "<br/>Name : " + data.name; }
          if (data.hasOwnProperty('reference')) { output = output + "<br/>Reference : " + data.reference; }
          if (data.hasOwnProperty('description')) { output = output + "<br/>Description : " + data.description; }
          if (data.hasOwnProperty('probability')) { output = output + "<br/>Probability : " + data.probability; }
          if (data.hasOwnProperty('impact')) { output = output + "<br/>Impact : " + data.impact; }
          if (data.hasOwnProperty('risk_owner')) { output = output + "<br/>Risk Owner Changed" }
          if (data.hasOwnProperty('treat')) { output = output + "<br/>Treatment Plan : " + data.treat; }
          if (data.hasOwnProperty('tolerate')) { output = output + "<br/>Tolerate Plan : " + data.tolerate; }
          if (data.hasOwnProperty('transfer')) { output = output + "<br/>Transfer Plan : " + data.transfer; }
          if (data.hasOwnProperty('terminate')) { output = output + "<br/>Terminate Plan : " + data.terminate; }
          if (data.hasOwnProperty('systems')) { output = output + "<br/>Systems Changed"; }
          if (data.hasOwnProperty('frameworks')) { output = output + "<br/>Frameworks Changed"; }

          if (output != "") {
            output = "Changes" + output;
          }

          return output;
        },

        systemOutput(data) {
          var output = "";

          if ((typeof data) == "string") {
            data =JSON.parse(data);
          }


          if (data.hasOwnProperty('name')) { output = output + "<br/>Name : " + data.name; }
          if (data.hasOwnProperty('availability')) { output = output + "<br/>availability : " + data.availability; }
          if (data.hasOwnProperty('category')) { output = output + "<br/>category : " + data.category; }
          if (data.hasOwnProperty('confidentiality')) { output = output + "<br/>confidentiality : " + data.confidentiality; }
          if (data.hasOwnProperty('integrity')) { output = output + "<br/>integrity : " + data.integrity; }
          if (data.hasOwnProperty('owner_user_id')) { output = output + "<br/>Asset Owner Changed" }
          if (data.hasOwnProperty('notes')) { output = output + "<br/>notes : " + data.notes; }

          if (output != "") {
            output = "Changes" + output;
          }

          return output;
        },

        taskOutput(data) {
          var output = "";

          if ((typeof data) == "string") {
            data =JSON.parse(data);
          }

          if (data.hasOwnProperty('reference_id')) { output = output + "<br/>Reference : " + data.reference_id; }
          if (data.hasOwnProperty('name')) { output = output + "<br/>Name : " + data.name; }
          if (data.hasOwnProperty('priority')) { output = output + "<br/>priority : " + data.priority; }
          if (data.hasOwnProperty('description')) { output = output + "<br/>description : " + data.description; }
          if (data.hasOwnProperty('assign_name')) { output = output + "<br/>Assign to : " + data.assign_name; }
          if (data.hasOwnProperty('completed_date')) { output = output + "<br/>Completed date : " + data.completed_date; }
          if (data.hasOwnProperty('created_date')) { output = output + "<br/>created date : " + data.created_date; }
          if (data.hasOwnProperty('due_date')) { output = output + "<br/>due date : " + data.due_date; }
          if (data.hasOwnProperty('expiry_date')) { output = output + "<br/>expiry date : " + data.expiry_date; }
          if (output != "") {
            output = "Changes" + output;
          }

          return output;
        },

        getAudits() {

            var data = {
                related_id: this.id,
                related_action: this.action,
            }

            console.log('data',data);

            ApiService.setHeader();
            ApiService.post("api/audit", data)
                .then(({ data }) => {
                    console.log(data);
                    this.audits= data.data.audits;
                })
                .catch(() => {
                    this.audits= [];
                });            

        },    

    },
    watch: {
        id: function() {
            this.getAudits();
        },
    },    
  }
</script>